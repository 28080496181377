<template>
<!-- eslint-disable -->
	<div style="height: 750px;">
		<h1 class="text-center my-6">日報の記入</h1>
		<v-row>
			<v-col cols="8">
				<DatePicker @changeDate="setDate"/>
			</v-col>
		</v-row>
		<v-textarea
			outlined
			label="本日の日報"
			v-model="content"
			height="300px"
			style="width:300px; margin: auto;"
			v-on:keydown.enter="enterDown()"
			v-bind:rules="upRules"
        ></v-textarea>
		<v-row style="max-width: 100% !important; margin: 0;">
			<v-btn
				class="mx-auto mt-4"
				:loading="loading"
				:disabled="loading"
				color="secondary"
				@click="upload()"
				style="width:150px;"
			>
				UPLOAD
			</v-btn>
		</v-row>

		<v-btn @click="fire()" fab :color="this.color" style="position: absolute !important; right: 30px; bottom: 100px;"><v-icon color="white">mdi-fire</v-icon></v-btn>

	</div>
</template>

<script>
/* eslint-disable */
import * as api from '@/api.js'
import DatePicker from '@/components/DatePicker.vue'
  export default {
	name: 'Upload',
	components: { DatePicker },
    data () {
      return {
        loader: null,
        loading: false,
	// 投稿
		menu: false,
		content: '',
		upRules: [text => text.length <= 595 || '最大文字数は595文字です'],
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      }
    },
	methods: {
		load(){
			this.content = ''
		},
		setDate(date) {
			this.date = date
		},


		upload(){
			this.loader = 'loading'
			api.upload.upload({date: this.date, content: this.content}).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop('', 'アップロードが成功しました！')
                        break
                    case 500:
						this.pop('アップロードが失敗しました', '※空の投稿はできません')
                        break
                }
			}).catch()
			this.content = ''
		},


		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},


		enterDown() {
			return this.content.replace(/\\n/g,'\n')
		},
		fire() {
			this.$router.push({name: 'Fire'})
		},
	},
	created() {
		this.load()
	},
	computed: {
        color() {
            return this.$store.getters.loginInfo.color
        }
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
      },
    },
  }
</script>
<style>
.gradation{
    background: linear-gradient(to left top, rgba(204, 204, 204, 0.3) 15%, white 5%, white 85%, rgba(204, 204, 204, 0.3) 10%);
}
.v-dialog > .v-card > .v-card__actions {
	margin: auto;
	max-width: 300px;
}
</style>